<template>
  <div class="container pt-3 mb-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"> </Heading>
    <small>
      View users
    </small>
    <hr />
    <el-form ref="form" @submit.native.prevent label-width="80px">
      <!-- <el-form-item label="Role">
        <el-radio-group v-model="role" @change="searchRole">
          <el-radio border :label="null">
            <i class="fa fa-users"></i>
            All
          </el-radio>
          <el-radio border label="teacher">
            <i class="fas fa-chalkboard-teacher"></i>
            Educators
          </el-radio>
          <el-radio border label="student">
            <i class="fas fa-user-graduate"></i>
            Students
          </el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="Search">
        <el-input
          placeholder="Email / Name"
          v-model="userQuery"
          @keyup.enter.native="searchUsers"
        >
          <el-button slot="append" icon="el-icon-search" @click="searchUsers">
            <span v-show="!isPhone">Search</span>
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <hr />
    <el-alert
      v-if="resultsTotal > -1"
      class="mt-3"
      type="success"
      :closable="false"
    >
      <span slot="title">
        Results: <b>{{ resultsTotal }}</b>
      </span>
    </el-alert>
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="isPhone ? 6 : 10"
        :small="isPhone"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table
      v-if="users"
      :size="isPhone ? 'mini' : ''"
      class="mt-4"
      :data="users"
      style="min-width: 100%"
      border
    >
      <el-table-column label="Name" min-width="140">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'SuperAdminViewUser',
              params: { uid: scope.row.id }
            }"
          >
            <b>
              {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
            </b>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Email" min-width="200">
        <template slot-scope="scope">
          <b> {{ scope.row.email }}</b>
        </template>
      </el-table-column>
      <el-table-column label="Role" min-width="100">
        <template slot-scope="scope">
          <template v-if="scope.row.roles && scope.row.roles.length > 0">
            <span v-for="(role, index) in scope.row.roles" :key="index">
              <el-tooltip
                class="item"
                effect="dark"
                content="Company Admin"
                placement="top"
              >
                <el-tag
                  v-if="role.name === 'organization_admin'"
                  type="success"
                  class="mr-2 max-color"
                >
                  <i class="fas fas fa-building"></i>
                </el-tag>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Educator"
                placement="top"
              >
                <el-tag
                  v-if="role.name === 'teacher'"
                  type="success"
                  class="mr-2 max-color"
                >
                  <i class="fas fa-chalkboard-teacher"></i>
                </el-tag>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Super Admin"
                placement="top"
              >
                <el-tag
                  v-if="role.name === 'super_admin'"
                  type="warning"
                  class="mr-2"
                >
                  <i class="fas fa-crown" />
                </el-tag>
              </el-tooltip>
            </span>
          </template>
          <template v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="Student"
              placement="top"
            >
              <el-tag type="success">
                <i class="fas fa-user-graduate"></i>
              </el-tag>
            </el-tooltip>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Plans" width="260">
        <template slot-scope="scope">
          <!-- <el-tag type="info" class="mb-2 mr-2">
            <b>Basic</b>
          </el-tag> -->
          <el-tag
            class="test-color mb-2 mr-2"
            v-for="plan in scope.row.subscriptions"
            :key="plan.id"
          >
            <b v-if="plan.subscription_plan.id === 6">
              Education Max
            </b>
            <b v-else>
              {{ plan.subscription_plan.name }}
            </b>
          </el-tag>
          <!-- <el-button type="text" @click="selectUser(scope.row)">
            <i class="fa fa-plus"></i>
          </el-button> -->
        </template>
      </el-table-column>
      <el-table-column label="Date Registered" min-width="80">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="isPhone ? 6 : 10"
        :small="isPhone"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <StudentsDialog
      ref="StudentsDialog"
      :showDialog="studentPlansShow"
      :userNow="userNow"
      :subscriptions="userNow ? userNow.subscriptions : []"
      :usePoints="true"
      @updateUsers="getUsers"
    ></StudentsDialog>
    <TeachersDialog
      ref="TeachersDialog"
      :showDialog="teacherPlansShow"
      :userNow="userNow"
      :subscriptions="userNow ? userNow.subscriptions : []"
      :usePoints="true"
      @updateUsers="getUsers"
    ></TeachersDialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Users from "@/views/users/apis/users.js";
import Breadcrumb from "@/components/Breadcrumb";
import StudentsDialog from "@/views/plans/components/StudentsDialog";
import TeachersDialog from "@/views/plans/components/TeachersDialog";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, StudentsDialog, TeachersDialog },

  mixins: [],

  props: [],
  data() {
    return {
      role: null,
      userQuery: null,
      users: null,
      resultsTotal: null,
      userNow: null,
      pageSize: 10,
      studentPlansShow: false,
      teacherPlansShow: false
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Users";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.search) {
      this.userQuery = this.$route.query.search;
    }
    if (this.$route.query.role) {
      this.role = this.$route.query.role;
    }
    this.getUsers();
  },

  methods: {
    selectUser(user) {
      this.userNow = null;
      this.userNow = user;
      if (user.roles.length > 0) {
        this.selectTeacher();
      } else {
        this.selectStudent();
      }
    },
    selectStudent() {
      this.$refs.StudentsDialog.selectStudent();
    },
    selectTeacher() {
      this.$refs.TeachersDialog.selectTeacher();
    },
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.userQuery !== "" ? this.userQuery : null
        }
      });
    },
    searchRole() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          role: this.role !== "" ? this.role : null
        }
      });
    },
    async getUsers() {
      let query = this.userQuery;
      const res = await Users.getUsers({
        search: query !== "" ? query : null,
        role: this.role,
        page: this.page
      });
      this.users = res.data;
      this.resultsTotal = res.total;
      this.pageSize = res.per_page;
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.free-color {
  background-color: #01b884;
}
.pro-color {
  background-color: #fa17e3;
}
.max-color {
  background-color: #1f7cff;
}
.free-color,
.pro-color,
.max-color {
  color: white;
  border: none;
}
</style>
